/* eslint-disable import/no-cycle */
import {
	captureMessage,
	addBreadcrumb,
	captureException,
} from '@sentry/vue';
import * as Sentry from '@sentry/vue';
import omit from 'lodash.omit';
import {
	getAllSiteElements,
	getStickyBarItems,
	sortLanguagesArray,
} from '@zyro-inc/site-modules/utils/siteDataUtils';
import axios from '@/services/http/axios';
import { initializeExperiments as initializeExperimentsUtil } from '@/utils/experiments';
import { createStore } from 'vuex';
import {
	setCookie,
	getCookie,
} from '@zyro-inc/site-modules/utils/cookies';
import {
	COOKIE_SITE_ID,
	WWW_REDIRECT_PATHS,
	REDIRECT_PARAM_KEYS,
	REDIRECT_PARAM_VALUES,
	NAVIGATION_TYPE_PAGE,
	PAGE_TYPE_DEFAULT_SLUG,
	PAGE_TYPE_BLOG_LIST_SLUG,
	SITE_ID_PLAYGROUND,
	LOCAL_STORAGE_KEY_HOTJAR_WHY_NOT_PUBLISHED_SENT,
} from '@/constants';
import { USER_SITE_TRANSLATIONS } from '@/data';

import {
	SYSTEM_LOCALE,
	PAGE_TYPE_BLOG,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
	PAGE_TYPE_PRIVATE,
	PAGE_ID_PRIVATE,
	META_ECOMMERCE_TYPE,
	ECOMMERCE_TYPE_ZYRO,
	ELEMENT_TYPE_BUTTON,
	ELEMENT_TYPE_ECOMMERCE_BUTTON,
	ELEMENT_TYPE_IMAGE,
	ELEMENT_TYPE_TEXT_BOX,
	ELEMENT_TYPE_FORM,
	GENERATED_SITE_TEMPLATE_ID,
	IMPORTED_SITE_TEMPLATE_ID,
	AI_GENERATED_TEMPLATE_ID,
	ANIMATION_TYPE_GLOBAL,
	ANIMATION_NOT_SUPPORTED_ELEMENTS,
	PREVIEW_SUBDOMAINS,
	BLOCK_TYPE_ECOMMERCE_PRODUCT,
	PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
} from '@zyro-inc/site-modules/constants';
import { HRESOURCE_TYPES } from '@/types/resourceTypes';
import {
	BLOCKS_ECOMMERCE,
	DEMO_PRODUCTS,
} from '@zyro-inc/site-modules/constants/ecommerce';
import {
	getBiggestIncrementedString,
	getInternalLinkWithoutQueryOrHash,
} from '@zyro-inc/site-modules/utils/modifyString';
import {
	filterObject,
	removeNullishEntries,
} from '@zyro-inc/site-modules/utils/object';
import { addGoogleFontQueryLinks } from '@/utils/injectableDomElements/addGoogleFontQueryLinks';
import assets from '@/store/builder/assets';
import blog from '@/store/builder/blog';
import colors from '@/store/builder/colors';
import ecommerce from '@/store/builder/ecommerce';
import fonts from '@/store/builder/fonts';
import { getFontsList } from '@zyro-inc/site-modules/utils/font';
import forms from '@/store/builder/forms';
import gui, { OPEN_MODAL } from '@/store/builder/gui';
import navigation from '@/store/builder/navigation';
import notifications from '@/store/builder/notifications';
import saving from '@/store/builder/saving';
import subscription from '@/store/builder/subscription';
import undoRedo from '@/store/builder/undoRedo';
import user from '@/store/builder/user';
import redirect from '@/store/builder/redirect';
import {
	useUserStore,
	useDomainStore,
	useIntercomStore,
} from '@/stores';
import EventLogApi from '@/api/EventLogApi';
import {
	publishSite,
	publishSiteHostinger,
	republishSite,
	republishSiteHostinger,
} from '@/api/PublishApi';
import { getSite } from '@/api/SitesApi';
import { getBillingSites } from '@/api/hPanelApi';
import { getTemplateById } from '@/api/TemplateApi';
import HResourcesApi from '@/api/HResourcesApi';
import {
	autoLoginWithDefault,
	getUser,
	logOut,
} from '@/api/UsersApi'; import {
	getBlogListBlock,
	getEcommerceProductListBlock,
	getEcommerceProductTemplate,
	getPageTitleBlock,
} from '@/components/block/blocks';
import {
	getRedirectUrlToWWW,
	getRedirectUrlToHostingerLogin,
	getRedirectUrlToDashboard,
} from '@/use/useRedirects';
import { generateRandomId } from '@/utils/generateRandomId';
import { patcher } from '@/utils/jsondiffpatch';
import { mergeObjects } from '@/utils/mergeObjects';
import { getHas7DaysPassed } from '@/utils/date';
import {
	BILLING_PERIODS,
	WEBSITE_STATES,
} from '@/constants/resources';

import getCloudflareErrorCallback from '@/utils/getCloudflareErrorCallback';
import {
	addPage,
	removePage,
	addBlock,
	removeBlock,
	addBlogPostTemplate,
	addEcommerceProductPageTemplate,
	clonePage,
	localizeHrefs,
	removeElement,
	mapFormIds,
	removePagesByPageTypeFromLanguage,
} from '@/utils/siteDataUtils';
import { isHostingerBrand } from '@/utils/isHostingerBrand';
import { updateElementHrefs } from '@/utils/hrefToMultilanguage';
import { getBlocksAndElementsWithGlobalAnimations } from '@/utils/siteEngineAnimations';
import { getLowestElementBottom } from '@zyro-inc/site-modules/utils/getLowestElementBottom';
import { getBlockIdByElementId } from '@/utils/layout';
import { getValidatedLayoutPositions } from '@/utils/getValidatedLayoutPositions';
import { nextTick } from 'vue';
import { updateElementPositionFromDOM } from '@/components/block-layout/useLayout';
import { useSiteStore } from '@/stores/siteStore';
import { getStoreId } from '@zyro-inc/site-modules/utils/getters/getStoreId';
import { getStoreProducts } from '@/api/StoreApi';
import { useRouter } from 'vue-router';
import { AI_BUILDER_ROUTE } from '@/constants/routes';
import { useEcommerceStore } from '@/stores/ecommerceStore';
import { isProductPage } from '@zyro-inc/site-modules/utils/ecommerce';
import { useGamificationStore } from '@/stores/gamificationStore';

export const SET_SITE_ID = 'SET_SITE_ID';
export const SET_CURRENT_PAGE_ID = 'SET_CURRENT_PAGE_ID';
export const SET_CURRENT_BLOCK_ID = 'SET_CURRENT_BLOCK_ID';
export const SET_CURRENT_ELEMENT_ID = 'SET_CURRENT_ELEMENT_ID';
export const SET_ELEMENT_EDIT_MODE = 'SET_ELEMENT_EDIT_MODE';
export const SET_BLOCK_EDIT_MODE = 'SET_BLOCK_EDIT_MODE';
export const SET_DEFAULT_BLOCK_EDIT_TAB = 'SET_DEFAULT_BLOCK_EDIT_TAB';
export const SET_MOST_RECENT_BUILDER_PAGE_ID = 'SET_MOST_RECENT_BUILDER_PAGE_ID';
export const SET_CURRENT_LOCALE = 'SET_CURRENT_LOCALE';
export const SET_ADD_ELEMENT_DATA = 'SET_ADD_ELEMENT_DATA';

const INITIAL_ZYRO_DOMAIN_PREFIX = 'mywebsite';

export const storeConfig = {
	modules: {
		assets,
		gui,
		undoRedo,
		user,
		subscription,
		navigation,
		notifications,
		forms,
		blog,
		colors,
		fonts,
		saving,
		ecommerce,
		redirect,
	},
	state: {
		websiteId: null,
		websiteStatus: '',
		zyroDomain: '',
		previewDomain: '',
		customDomain: '',
		currentPageId: null,
		currentBlockId: null,
		currentElementId: null,
		mostRecentBuilderPageId: null,
		template: null,
		isAppLoading: false,
		isRouteLoading: false,
		hasBuilderInitialized: false,
		isElementEditMode: false,
		isBlockEditorOpen: false,
		defaultBlockEditTab: '',
		currentElementSnapshot: null,
		currentLocale: SYSTEM_LOCALE,
		generatedWebsite: null,
		addElementData: {},
		isQATestUser: false,
		createdAt: '',
		hResourceId: '',
		expiresAt: '',
	},
	getters: {
		siteCustomDomainUrl: (state, getters) => `https://${getters.siteMeta.shouldAddWWWPrefixToDomain ? 'www.' : ''}${state.customDomain}`,
		siteZyroDomainUrl: (state) => `https://${state.zyroDomain || `${INITIAL_ZYRO_DOMAIN_PREFIX}${import.meta.env.VITE_PUBLISH_DOMAIN}`}`,
		sitePreviewDomainUrl: (state) => `https://${state.previewDomain}`,
		siteUrl: (state, getters) => (state.customDomain ? getters.siteCustomDomainUrl : getters.siteZyroDomainUrl),
		siteLanguages: () => {
			const siteStore = useSiteStore();

			return siteStore.site.languages ?? {};
		},
		siteLanguagesArray: (state, getters) => Object.entries(getters.siteLanguages)
			.filter(([locale]) => locale !== SYSTEM_LOCALE)
			.map(([locale, languageData]) => ({
				...languageData,
				locale,
			})) ?? [],
		siteLanguagesSortedArray: (state, getters) => sortLanguagesArray(getters.siteLanguagesArray),
		isCurrentSystemLocale: (state) => state.currentLocale === SYSTEM_LOCALE,
		currentLanguageData: (state, getters) => getters.siteLanguages[state.currentLocale] ?? {},
		systemLanguageData: (state, getters) => getters.siteLanguages[SYSTEM_LOCALE],
		systemLanguageBlocks: (state, getters) => getters.systemLanguageData.blocks ?? {},
		systemLanguageElements: (state, getters) => getters.systemLanguageData.elements ?? {},
		privatePage: (state, getters) => Object.values(getters.siteLanguages[SYSTEM_LOCALE].pages)
			.find(({ type }) => type === PAGE_TYPE_PRIVATE),
		privatePageBlocks: (state, getters) => Object.fromEntries(Object.entries(getters.systemLanguageBlocks).filter(([blockId]) => {
			const isPrivateBlock = getters.privatePage?.blocks.includes(blockId);

			return isPrivateBlock;
		})),
		privatePageElementsIds: (state, getters) => Object.values(getters.privatePageBlocks)
			.flatMap((block) => block.components),
		privatePageElements: (state, getters) => Object.fromEntries(Object.entries(getters.systemLanguageElements).filter(([elementId]) => {
			const isPrivateElement = getters.privatePageElementsIds.includes(elementId);

			return isPrivateElement;
		})),
		siteBlocks: (state, getters) => {
			const currentLanguageBlocks = getters.currentLanguageData?.blocks ?? {};

			if (state.currentLocale === SYSTEM_LOCALE) {
				return currentLanguageBlocks;
			}

			return {
				...currentLanguageBlocks,
				...getters.privatePageBlocks,
			};
		}, // languageBlocks
		siteElements: (state, getters) => {
			const currentLanguageElements = getters.currentLanguageData?.elements ?? {};

			if (state.currentLocale === SYSTEM_LOCALE) {
				return currentLanguageElements;
			}

			return {
				...currentLanguageElements,
				...getters.privatePageElements,
			};
		}, // languageElements
		defaultLanguageSitePages: (state, getters) => getters.siteLanguages[getters.defaultLocale]?.pages ?? {}, // languagePages
		sitePages: (state, getters) => {
			const currentLanguagePages = getters.currentLanguageData?.pages ?? {};

			if (state.currentLocale === SYSTEM_LOCALE) {
				return currentLanguagePages;
			}

			return {
				...currentLanguagePages,
				[PAGE_ID_PRIVATE]: {
					...getters.privatePage,
				},
			};
		}, // languagePages
		siteHomePage: (state, getters) => getters.sitePages[getters.homePageId], // languageHomePage
		ecommerceShoppingCart: () => {
			const siteStore = useSiteStore();

			return siteStore.site.ecommerceShoppingCart;
		},
		siteHomePageTitle: (state, getters) => getters.siteHomePage?.meta?.title || getters.siteHomePage.name,
		siteForms: () => {
			const siteStore = useSiteStore();

			return siteStore.site.forms ?? {};
		},
		siteMeta: () => {
			const siteStore = useSiteStore();

			return siteStore.site.meta ?? {};
		},
		siteStyles: () => {
			const siteStore = useSiteStore();

			return siteStore.site.styles ?? {};
		},
		siteFonts: (state, getters) => getters.siteStyles.font, // TODO: write a mapper to rename it to 'website.fonts'
		siteTemplate: (state, getters) => getters.siteMeta.template,
		siteNav: (state, getters) => getters.currentLanguageData?.nav ?? [], // languageNav
		isNavHidden: (state, getters) => !!getters.currentLanguageData.isNavHidden,
		blogReadingTimeText: (state, getters) => getters.currentLanguageData.blogReadingTimeText,
		currentPage: (state, getters) => getters.sitePages[state.currentPageId],
		currentBlock: (state, getters) => getters.siteBlocks[state.currentBlockId],
		currentBlockType: (state, getters) => getters.currentBlock?.type,
		currentBlockSettings: (state, getters) => getters.currentBlock?.settings,
		currentBlockStyles: (state, getters) => getters.currentBlockSettings?.styles,
		currentBlockSlot: (state, getters) => getters.currentBlock?.slot,
		currentElement: (state, getters) => getters.siteElements[state.currentElementId],
		currentElementId: (state) => state.currentElementId,
		currentElementContent: (state, getters) => getters.currentElement?.content,
		currentElementSettings: (state, getters) => getters.currentElement?.settings,
		currentElementStyles: (state, getters) => getters.currentElementSettings?.styles,
		currentElementType: (state, getters) => getters.currentElement?.type,
		currentElementBlockId: (state, getters) => getBlockIdByElementId({
			elementId: state.currentElementId,
			siteBlocks: getters.siteBlocks,
		}),
		currentElementBlock: (state, getters) => getters.siteBlocks[getters.currentElementBlockId],
		currentElementBlockType: (state, getters) => getters.currentElementBlock?.type,
		isEditingTextBoxElement: (state, getters) => state.isElementEditMode && getters.currentElementType === ELEMENT_TYPE_TEXT_BOX,
		elementBlockId: (state, getters) => (elementId) => Object.keys(getters.siteBlocks)
			.find((blockId) => getters.siteBlocks[blockId].components?.includes(elementId)),
		headerBlock: (state, getters) => getters.siteBlocks.header, // languageHeaderBlock
		footerBlock: (state, getters) => Object.values(getters.siteBlocks).find((block) => block.slot === 'footer'),
		doesFooterExist: (state, getters) => !!getters.footerBlock,
		hasGeneratedTemplate: (state, getters) => (getters.siteTemplate === GENERATED_SITE_TEMPLATE_ID),
		hasImportedTemplate: (state, getters) => (getters.siteTemplate === IMPORTED_SITE_TEMPLATE_ID),
		hasBlankTemplate: (state, getters) => (getters.siteTemplate === 'blank'),
		hasLanguages: (state, getters) => getters.siteLanguagesArray.length > 0,
		doesPageIdAlreadyExist: (state, getters) => ({ pageId }) => Object.keys(getters.sitePages).includes(pageId),
		isCurrentPagePrivate: (state) => state.currentPageId === PAGE_ID_PRIVATE,
		isCurrentPageTypeBlog: (state, getters) => getters.currentPage?.type === PAGE_TYPE_BLOG,
		isCurrentPageTypeDynamicProduct: (state, getters) => getters.currentPage?.type === PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
		isCurrentPageTypeEcommerceProduct: (state, getters) => isProductPage(getters.currentPage?.type),
		isCurrentPageTypeLegacyEcommerceProduct: (state, getters) => getters.currentPage?.type === PAGE_TYPE_ECOMMERCE_PRODUCT,
		isCurrentPageEmpty: (state, getters) => getters.currentPage && getters.currentPage.blocks.length === 0,
		isPageSlugUnique: (state, getters) => ({
			slug,
			slugPageId,
		}) => !Object.entries(getters.sitePages).some(
			([pageId, page]) => pageId !== slugPageId && page.slug === slug,
		),
		templateType: (state, getters) => (getters.hasGeneratedTemplate || getters.hasImportedTemplate || getters.hasBlankTemplate ? getters.siteTemplate : 'template'),
		blogCategories: () => {
			const siteStore = useSiteStore();

			return siteStore.site.blogCategories ?? {};
		},
		homePage: (state, getters) => getters.sitePages[getters.homePageId],
		homePageId: (state, getters) => getters.currentLanguageData.homePageId,
		defaultLocale: (state, getters) => getters.siteMeta.defaultLocale ?? SYSTEM_LOCALE,
		isLanguageSwitcherHidden: (state, getters) => getters.headerBlock?.settings?.isLanguageSwitcherHidden,
		defaultPages: (state, getters) => filterObject(
			getters.sitePages,
			({ value }) => value.type === 'default',
		),
		blogPages: (state, getters) => filterObject(
			getters.sitePages,
			({ value }) => value.type === 'blog',
		),
		ecommerceLocaleProductPages: (state, getters) => filterObject(
			getters.sitePages,
			({ value }) => value.type === PAGE_TYPE_ECOMMERCE_PRODUCT,
		),
		// zyro ecommerce pages are not adapted for multi-language so we need to have a getter for default lang website pages
		ecommerceDynamicProductPageTemplates: (state, getters) => filterObject(
			getters.defaultLanguageSitePages,
			({ value }) => value.type === PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
		) || {},
		ecommerceLegacyProductPages: (state, getters) => filterObject(
			getters.defaultLanguageSitePages,
			({ value }) => value.type === PAGE_TYPE_ECOMMERCE_PRODUCT,
		),
		ecommerceProductPages: (state, getters) => filterObject(
			getters.defaultLanguageSitePages,
			({ value }) => [
				PAGE_TYPE_ECOMMERCE_PRODUCT,
				PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
			].includes(value.type),
		),
		draftBlogPages: (state, getters) => filterObject(
			getters.blogPages,
			({ value }) => value.isDraft && !value.isScheduled,
		),
		scheduledBlogPages: (state, getters) => filterObject(
			getters.blogPages,
			({ value }) => value.isScheduled,
		),
		publishedBlogPages: (state, getters) => filterObject(
			getters.blogPages,
			({ value }) => !value.isDraft && !value.isScheduled,
		),
		websiteStatus: (state) => state.websiteStatus,
		addElementData: (state) => state.addElementData,
		isStoreAvailableForUser: (state, getters, rootState, rootGetters) => !isHostingerBrand || rootGetters['user/isZyroUser'],
		isSitePublished: (state) => !!state.zyroDomain,
		builderCompletedSteps: () => {
			const siteStore = useSiteStore();

			return siteStore.site.builderCompletedSteps;
		},
		isQATestUser: (state) => state.isQATestUser,
		isCustomDomainPreview: (state) => state.customDomain && PREVIEW_SUBDOMAINS.some(
			(previewDomain) => state.customDomain?.endsWith(previewDomain),
		),
		isLocalDevelopmentPlaygroundMode: (state) => import.meta.env.DEV && state.websiteId === SITE_ID_PLAYGROUND,
		getIsSiteCreatedAfterTimestamp: (state) => ({ timestamp }) => {
			const siteCreationTimestamp = Math.floor(new Date(state.createdAt).getTime() / 1000);

			return siteCreationTimestamp > timestamp;
		},
	},
	mutations: {
		// App
		setIsRouteLoading: (state, value) => {
			state.isRouteLoading = value;
		},
		setIsAppLoading: (state, value) => {
			state.isAppLoading = value;
		},
		setHasBuilderInitialized: (state, value) => {
			state.hasBuilderInitialized = value;
		},
		[SET_SITE_ID]: (state, siteId) => {
			state.websiteId = siteId;
		},
		[SET_ELEMENT_EDIT_MODE]: (state, value) => {
			state.isElementEditMode = value;
		},
		[SET_BLOCK_EDIT_MODE]: (state, value) => {
			state.isBlockEditorOpen = value;
		},
		[SET_DEFAULT_BLOCK_EDIT_TAB]: (state, options) => {
			state.defaultBlockEditTab = options;
		},
		[SET_CURRENT_ELEMENT_ID]: (state, elementId) => {
			state.currentElementId = elementId;
		},
		[SET_MOST_RECENT_BUILDER_PAGE_ID]: (state, pageId) => {
			state.mostRecentBuilderPageId = pageId;
		},
		setWebsiteStatus: (state, status) => {
			state.websiteStatus = status;
		},
		setZyroDomain: (state, zyroDomain) => {
			state.zyroDomain = zyroDomain;
		},
		setPreviewDomain: (state, previewDomain) => {
			state.previewDomain = previewDomain;
		},
		setCustomDomain: (state, customDomain) => {
			state.customDomain = customDomain;
		},
		setTemplate: (state, payload) => {
			state.template = payload;
		},
		[SET_CURRENT_LOCALE]: (state, locale = SYSTEM_LOCALE) => {
			state.currentLocale = locale;
		},
		// Page
		[SET_CURRENT_PAGE_ID]: (state, pageId) => {
			state.currentPageId = pageId;
		},
		// Block
		[SET_CURRENT_BLOCK_ID]: (state, blockId) => {
			state.currentBlockId = blockId;
		},
		setGeneratedWebsite: (state, website) => {
			state.generatedWebsite = website;
		},
		[SET_ADD_ELEMENT_DATA]: (state, value) => {
			state.addElementData = {
				...value,
			};
		},
		setIsQATestUser: (state, value) => {
			state.isQATestUser = value;
		},
		setCreatedAt: (state, value) => {
			state.createdAt = value;
		},
		setHResourceId: (state, value) => {
			state.hResourceId = value;
		},
		setExpiresAt: (state, value) => {
			state.expiresAt = value;
		},
	},
	actions: {
		initHResourcesData: async ({
			state,
			commit,
			dispatch,
		}) => {
			try {
				const resources = await HResourcesApi.getUserResources({
					types: [
						HRESOURCE_TYPES.WEBSITE_BUILDER,
						HRESOURCE_TYPES.HOSTING,
					],
				});

				const websiteBuilderResources = resources.filter(({ type }) => type === HRESOURCE_TYPES.WEBSITE_BUILDER);
				const hostingResources = resources.filter(({ type }) => type === HRESOURCE_TYPES.HOSTING);

				// billing sites are needed as they have published status
				const billingSites = await getBillingSites();

				const siteHResourceId = state.hResourceId;
				const websiteResource = websiteBuilderResources?.find(({ id }) => id === Number(siteHResourceId));
				const websiteHosting = hostingResources?.find(({ id }) => id === websiteResource?.related_resource_id);

				const currentHostingPublishedSites = websiteBuilderResources?.filter((website) => {
					const isCurrentHostingWebsite = websiteHosting?.id === website.related_resource_id
						&& website.state !== WEBSITE_STATES.DELETED;
					const isPublishedSite = billingSites?.data?.some(
						({
							hResourceId,
							published,
						}) => Number(hResourceId) === Number(website.id) && !!published,

					);

					return isCurrentHostingWebsite && isPublishedSite;
				});

				const isAnySitePublished = !!currentHostingPublishedSites?.length;

				const has7DaysPassedSinceWebsiteCreation = getHas7DaysPassed(websiteResource?.created_at);

				const billingPeriod = websiteHosting?.metadata?.billing_period;
				const isBillingPeriodOverAYear = billingPeriod && billingPeriod !== BILLING_PERIODS.MONTHLY;

				const isEnglishLanguage = window.navigator.language.startsWith('en');

				const userId = state.user?.user?.id;

				const localStorageKey = `${LOCAL_STORAGE_KEY_HOTJAR_WHY_NOT_PUBLISHED_SENT}-${userId}`;
				const isEventSent = !!window.localStorage.getItem(localStorageKey);

				if (
					userId
					&& !isEventSent
					&& !isAnySitePublished
					&& isEnglishLanguage
					&& has7DaysPassedSinceWebsiteCreation
					&& isBillingPeriodOverAYear
					&& window.hj
				) {
					window.localStorage.setItem(localStorageKey, true);

					window.hj('event', 'website_builder.hotjar.why_not_published_v2');
				}

				commit('setExpiresAt', websiteResource?.expires_at);
			} catch (error) {
				dispatch('notifications/notify', {
					messageI18nKeyPath: 'builder.errorWhileLoadingHResource',
				});

				captureException(error);
			}
		},
		removeNonExistingBlocksFromPages: ({
			getters,
			state,
		}) => {
			const siteStore = useSiteStore();
			const { pages } = getters.currentLanguageData;

			Object.entries(pages).forEach(([pageId, page]) => {
				if (!page.blocks) {
					Sentry.captureException(new Error('Undefined blocks in page', {
						pageId,
						siteId: state.websiteId,
					}));

					return;
				}

				const nonExistingBlocks = page.blocks.filter((block) => !Object.keys(getters.siteBlocks).includes(block));

				if (nonExistingBlocks.length) {
					siteStore.removeNonExistingBlocksFromSite({
						pageId,
						nonExistingBlocks,
					});

					Sentry.captureException(new Error('Non existing block referenced in page', {
						pageId,
						siteId: state.websiteId,
						nonExistingBlocks,
					}));
				}
			});
		},
		initializeExperiments: async ({
			getters,
			dispatch,
		}) => {
			await dispatch('user/getUser');
			await initializeExperimentsUtil({
				userId: getters['user/userHpanelId'],
				isGoRobotsUser: getters['user/isGoRobotsUser'] || getters['user/isPanelRegressionUser'],
			});
		},
		// #region General
		initBuilder: async ({
			commit,
			dispatch,
			getters,
		}, { siteId }) => {
			const userStore = useUserStore();
			const domainStore = useDomainStore();
			const gamificationStore = useGamificationStore();
			const router = useRouter();

			commit('setIsAppLoading', true);

			if (!import.meta.env.VITE_DISABLE_CHALLENGE_PAGE) {
				axios.interceptors.response.use(
					null,
					getCloudflareErrorCallback(axios),
				);
			}

			const isDevelopmentWithPlaygroundSiteData = import.meta.env.DEV && siteId === 'playground';
			const isDevelopmentWithDefaultSiteData = import.meta.env.DEV && siteId === 'default';

			let website;

			try {
				if (isDevelopmentWithDefaultSiteData) {
					// local development with default site data from DB
					try {
						website = await getSite(import.meta.env.VITE_DEFAULT_SITE_ID);
					} catch (error) {
						if (error?.response?.status === 401) {
							await autoLoginWithDefault();
							window.location.reload();
						}

						// probably user is logged in to different account
						if (error?.response?.status === 404) {
							const { id: userId } = await getUser();

							if (userId !== import.meta.env.VITE_DEFAULT_USER_ID) {
								await logOut();
								await autoLoginWithDefault();
								window.location.reload();
							}
						}
					}
				} else if (isDevelopmentWithPlaygroundSiteData) {
					// Local development setup
					const playgroundModules = import.meta.glob('@zyro-inc/site-modules/templates/main.json', {
						query: '?raw',
						eager: true,
						import: 'default',
					});

					const [rawPlaygroundJson] = Object.values(playgroundModules);
					const playgroundSiteData = JSON.parse(rawPlaygroundJson);

					website = {
						data: playgroundSiteData,
						id: playgroundSiteData.siteId,
					};
				} else if (siteId === 'legacy') {
					// Temporary support legacy entrance: (cached www/crm, missed code places)
					captureMessage('Legacy builder entrance occurred');
					const currentSiteId = getCookie(COOKIE_SITE_ID);

					if (!currentSiteId) {
						window.location.assign(getRedirectUrlToDashboard({
							path: WWW_REDIRECT_PATHS.SITES,
						}));
					} else {
						website = await getSite(currentSiteId);
					}
				} else {
					// Prod setup
					// Get site according to https://editor.zyro.com/{siteId}
					website = await getSite(siteId);
					setCookie(COOKIE_SITE_ID, siteId, 365);

					Sentry.setTags({
						siteId,
						domain: website.domain,
					});
				}
			} catch (error) {
				if (error?.response?.status === 401) {
					if (isHostingerBrand) {
						window.location.assign(getRedirectUrlToHostingerLogin());
					} else {
						window.location.assign(getRedirectUrlToWWW({
							path: WWW_REDIRECT_PATHS.SIGN_IN,
							params: {
								[REDIRECT_PARAM_KEYS.RETURN]: REDIRECT_PARAM_VALUES.RETURN_BUILDER,
							},
						}));
					}
				} else {
					window.location.assign('/site-not-found');

					return;
				}

				captureException(error);

				return;
			}

			dispatch('overwriteWebsiteData', {
				websiteData: website.data,
				websiteId: website.id,
			});

			commit('setCreatedAt', website.created);

			userStore.setAreFeaturesLocked(website.isFeaturesLocked || false);

			addBreadcrumb({
				category: 'CLIENT_TIMESTAMP',
				message: 'Initial website fetch',
				data: {
					clientTimestamp: website.clientTimestamp,
				},
			});

			dispatch('saving/updateClientTimestamp', website.clientTimestamp);
			dispatch('saving/createSiteDataSnapshot', {
				siteData: website.data,
			});
			dispatch('updateCurrentLocale', getters.defaultLocale);
			dispatch('undoRedo/setInitialSiteData');
			dispatch('updateCurrentPageId');

			// If local template is loaded up (without user data), omit fetching subscriptions/user/etc, since fetches will fail
			if (!isDevelopmentWithPlaygroundSiteData) {
				await dispatch('subscription/getSitesSubscriptions');
				await dispatch('user/getUser');
				await dispatch('user/setHostingerDomain');
				await dispatch('subscription/getSubscription');

				dispatch('fonts/fetchGoogleFonts');
				dispatch('assets/fetchAssets');

				// Domain is treated as a string in most places, but from DB often returned as 'null'.
				commit('setZyroDomain', website.domain ?? '');
				commit('setPreviewDomain', website.previewDomain ?? '');

				domainStore.setCustomDomain(website.customDomain);
				commit('setCustomDomain', website.customDomain);
				commit('setWebsiteStatus', website.status);
				commit('assets/setAssetPaths', website.assetsData || {});
				gamificationStore.setCreatedAt(website.created);
				commit('setHResourceId', website.hResourceId);

				dispatch('initHResourcesData');

				await dispatch('initializeExperiments');

				domainStore.setHDomainsStatusConfig();

				EventLogApi.logEvent({
					eventName: 'website_builder.builder.enter',
					eventProperties: {
						website_id: siteId,
						screen_size: window.screen.width,
					},
				});
			}

			if (getters['fonts/getMetaFont']) {
				addGoogleFontQueryLinks(getters['fonts/getMetaFont']);
			}

			if (getters['ecommerce/isStoreTypeZyro']) {
				const currentRoute = router.currentRoute.value.path?.substring(1);
				const shouldPickStylesFromTheme = currentRoute === AI_BUILDER_ROUTE;

				await dispatch('ecommerce/initEcommerce', {
					shouldPickStylesFromTheme,
				});
			}

			commit('setHasBuilderInitialized', true);
			commit('setIsAppLoading', false);
			dispatch('forms/initForms');
		},
		getTemplate: async ({
			dispatch,
			commit,
		}, { templateId }) => {
			try {
				const template = await getTemplateById(templateId);

				commit('setTemplate', template);
			} catch (error) {
				dispatch('notifications/notify', {
					messageI18nKeyPath: 'builder.errorWhileLoadingTemplateStructure',
				});

				captureException(error);
			}
		},
		publishWebsite: async ({
			state,
			commit,
			getters,
			dispatch,
		}, {
			zyroDomain,
			previewDomain,
			shouldOpenPublishModal = true,
		}) => {
			const { websiteId } = state;
			const siteStore = useSiteStore();

			try {
				await dispatch('saving/saveWebsite', {
					saveWhenImpersonating: true,
				});

				if (getters['saving/canSave']) {
					return;
				}

				if (isHostingerBrand) {
					await publishSiteHostinger(zyroDomain, websiteId);

					EventLogApi.logEvent({
						eventName: 'website_builder.builder.website_published',
						eventProperties: {
							website_id: websiteId,
							textBoxChangeCount: getters.builderCompletedSteps?.textBoxChangeCount ?? 0,
							screen_size: window.screen.width,
							domain_type: state.customDomain ? 'custom' : 'preview',
							builder_type: getters.siteMeta.template === AI_GENERATED_TEMPLATE_ID ? 'ai' : 'template',
							private_mode: siteStore.isPrivateModeActive ? 'on' : 'off',
						},
					});
				} else {
					await publishSite(zyroDomain, websiteId);
				}

				commit('setZyroDomain', zyroDomain);
				commit('setPreviewDomain', previewDomain);

				if (shouldOpenPublishModal) {
					dispatch(`gui/${OPEN_MODAL}`, {
						name: 'PublishedModal',
					});
				}
			} catch (error) {
				commit('setZyroDomain', '');

				dispatch('notifications/notify', {
					headingI18nKeyPath: 'builder.publishErrorModal.title',
					messageI18nKeyPath: 'builder.publishErrorModal.subtitle',
					submitLabelI18nKeyPath: 'common.tryAgain',
					isDiscardButtonShown: false,
					submitCallback: () => dispatch('publishWebsite', {
						zyroDomain,
						previewDomain,
					}),
				});

				captureException(error);
			}
		},
		setIsQATestUser: ({ commit }, value) => {
			commit('setIsQATestUser', value);
		},
		updateWebsite: async ({
			state,
			getters,
			dispatch,
		}, payload = {
			showModal: true,
		}) => {
			const { websiteId } = state;
			const siteStore = useSiteStore();

			try {
				await dispatch('saving/saveWebsite', {
					saveWhenImpersonating: true,
				});

				if (getters['saving/canSave']) {
					return;
				}

				if (isHostingerBrand) {
					await republishSiteHostinger(websiteId, siteStore.isCustomCodeDisabled);

					EventLogApi.logEvent({
						eventName: 'website_builder.builder.website_update',
						eventProperties: {
							website_id: websiteId,
							textBoxChangeCount: getters.builderCompletedSteps?.textBoxChangeCount ?? 0,
							screen_size: window.screen.width,
							private_mode: siteStore.isPrivateModeActive ? 'on' : 'off',
						},
					});
				} else {
					await republishSite(websiteId);
				}

				if (payload.showModal) {
					dispatch(`gui/${OPEN_MODAL}`, {
						name: 'PublishedModal',
						settings: {
							isUpdate: true,
						},
					});
				}
			} catch (error) {
				dispatch('notifications/notify', {
					headingI18nKeyPath: 'builder.publishErrorModal.title',
					messageI18nKeyPath: 'builder.publishErrorModal.subtitle',
					submitLabelI18nKeyPath: 'common.tryAgain',
					isDiscardButtonShown: false,
					submitCallback: () => dispatch('updateWebsite'),
				});

				captureException(error);
			}
		},
		resetSiteData: ({ commit }) => {
			const siteStore = useSiteStore();

			siteStore.setSiteData({});
			siteStore.setSiteCurrentPageId(null);

			commit(SET_SITE_ID, null);
			siteStore.setSiteId(null);
			commit(SET_CURRENT_PAGE_ID, null);
			commit(SET_CURRENT_BLOCK_ID, null);
			siteStore.setSiteCurrentBlockId(null);
			commit(SET_CURRENT_ELEMENT_ID, null);
			commit('setHasBuilderInitialized', false);
		},
		overwriteWebsiteData: ({
			dispatch,
			commit,
		}, {
			websiteData,
			websiteId,
		}) => {
			const siteStore = useSiteStore();
			const intercomStore = useIntercomStore();

			if (websiteData) {
				siteStore.setSiteData(websiteData);
				if (websiteId) {
					commit(SET_SITE_ID, websiteId);
					siteStore.setSiteId(websiteId);
				}
			} else {
				// if incoming websiteData is malformed, show notification and open Intercom
				dispatch('notifications/notify', {
					messageI18nKeyPath: 'builder.overwriteWebsiteDataNotification',
					// only show recovery and open chat when Intercom is present in window:
					submitLabelI18nKeyPath: window.Intercom ? 'common.reportError' : null,
					submitCallback: () => intercomStore.handleIntercomOpen(),
				});
				// ...and dispatch custom error to Sentry
				Sentry.captureException(new Error(`Trying to set website data as: ${websiteData}`), {
					tags: {
						errorType: 'Invalid data.json',
					},
				});
			}
		},
		updateInternalLinks: ({
			getters,
			dispatch,
		}, {
			oldLink,
			newLink,
		}) => {
			// Update elements containing internal links
			Object.entries(getters.siteElements).forEach(([elementId, element]) => {
				if (!element) {
					return;
				}

				// Images and buttons have the same structure, so we can replace them in the same way
				const elementsWithUpdatableHref = [
					ELEMENT_TYPE_BUTTON,
					ELEMENT_TYPE_IMAGE,
				];

				if (elementsWithUpdatableHref.includes(element.type)) {
					const hrefWithoutQueryOrHash = getInternalLinkWithoutQueryOrHash(element.settings.href);

					if (hrefWithoutQueryOrHash === oldLink) {
						dispatch('mergeElementData', {
							elementId,
							elementData: {
								settings: {
									href: element.settings.href.replace(oldLink, newLink),
								},
							},
						});
					}
				} else if (element.type === ELEMENT_TYPE_TEXT_BOX) {
					const temporaryElement = document.createElement('div');

					temporaryElement.innerHTML = element.content;
					[...temporaryElement.getElementsByTagName('a')].forEach((anchorElement) => {
						const anchorHref = anchorElement.getAttribute('href');
						const anchorHrefWithoutQueryOrHash = getInternalLinkWithoutQueryOrHash(anchorHref);

						if (anchorHrefWithoutQueryOrHash === oldLink) {
							anchorElement.setAttribute('href', anchorHref.replace(oldLink, newLink));
						}
					});

					if (temporaryElement.innerHTML !== element.content) {
						dispatch('mergeElementData', {
							elementId,
							elementData: {
								content: temporaryElement.innerHTML,
							},
						});
					}
				}
			});

			// Update navigation links containing internal links
			getters.siteNav.forEach((navItem) => {
				const linkHrefWithoutQueryOrHash = getInternalLinkWithoutQueryOrHash(navItem.href);

				if (oldLink === linkHrefWithoutQueryOrHash) {
					dispatch('navigation/setItemData', {
						data: {
							...navItem,
							href: newLink,
						},
					});
				}
			});
		},
		updateMostRecentBuilderPageId: ({ commit }, pageId) => {
			commit(SET_MOST_RECENT_BUILDER_PAGE_ID, pageId);
		},
		updateFonts: ({
			state,
			getters,
		}) => {
			const siteStore = useSiteStore();
			const { googleFonts } = state.fonts;
			const allSiteElements = getAllSiteElements({
				languages: getters.siteLanguages,
			});
			const allStickyBarTextItems = getStickyBarItems({
				languages: getters.siteLanguages,
			});

			const allTextElementsHtml = allSiteElements
				.filter(({ type }) => type === ELEMENT_TYPE_TEXT_BOX)
				.map(({ content }) => content)
				.join();
			const allNonGridTextElementsHtml = allStickyBarTextItems
				.filter(({ type }) => type === 'text')
				.map(({ content }) => content)
				.join();

			const fontsInUse = getFontsList({
				siteData: siteStore.site,
				html: `${allTextElementsHtml},${allNonGridTextElementsHtml}`,
				customFonts: getters['assets/customFonts'],
				googleFonts,
			});

			siteStore.setSiteFontsData(fontsInUse);
		},
		updateAddElementData: ({ commit }, data) => {
			commit(SET_ADD_ELEMENT_DATA, data);
		},
		addBuilderCompletedStep({ getters }, completedStep) {
			const siteStore = useSiteStore();

			siteStore.setSiteBuilderCompletedStepsData({
				...getters.builderCompletedSteps,
				...completedStep,
			});
		},
		removeMetaProperty(_, propKey) {
			const siteStore = useSiteStore();

			const {
				[propKey]: _propValue,
				...cleanMeta
			} = siteStore.site.meta;

			siteStore.setSiteData({
				...siteStore.site,
				meta: cleanMeta,
			});
		},
		// #endregion
		// #region Element
		selectCurrentElement: ({ commit }, { elementId }) => {
			commit(SET_CURRENT_ELEMENT_ID, elementId);
		},
		unselectCurrentElement: ({ commit }) => {
			commit(SET_CURRENT_ELEMENT_ID, null);
		},
		enterElementEditMode: ({ commit }) => {
			commit(SET_ELEMENT_EDIT_MODE, true);
		},
		leaveElementEditMode: ({
			dispatch,
			commit,
		}, { saveToHistory = true } = {}) => {
			if (saveToHistory) {
				dispatch('undoRedo/createSnapshot');
			}

			commit(SET_ELEMENT_EDIT_MODE, false);
		},
		enterBlockEditMode: ({ commit }) => {
			EventLogApi.logEvent({
				eventName: 'website_builder.section_settings.enter',
			});

			commit(SET_BLOCK_EDIT_MODE, true);
		},
		leaveBlockEditMode: ({ commit }) => {
			commit(SET_BLOCK_EDIT_MODE, false);
			commit(SET_DEFAULT_BLOCK_EDIT_TAB, '');
		},
		setDefaultBlockEditTab: ({ commit }, options) => {
			commit(SET_DEFAULT_BLOCK_EDIT_TAB, options);
		},
		addElement: ({
			state,
			getters,
			dispatch,
		}, {
			blockId,
			elementId = generateRandomId(),
			elementData,
			mobilePosition = null,
			locale = state.currentLocale,
		}) => {
			try {
				const siteStore = useSiteStore();
				const elementLocale = getters.isCurrentPagePrivate ? SYSTEM_LOCALE : locale;

				const elementDataCopy = {
					...elementData,
				};
				const isElementEcommerceButton = elementDataCopy.type === ELEMENT_TYPE_ECOMMERCE_BUTTON;

				// Generate action comes first to create a correct diff of website data
				if (isElementEcommerceButton) {
					dispatch('generateEcommercePages');
				}

				const siteDataClone = patcher.clone(siteStore.site);

				// Handle global animations
				const itemWithGlobalAnimation = getBlocksAndElementsWithGlobalAnimations({
					blocks: siteDataClone.languages[elementLocale].blocks,
					elements: siteDataClone.languages[elementLocale].elements,
				});

				if (itemWithGlobalAnimation && !ANIMATION_NOT_SUPPORTED_ELEMENTS.includes(elementData.type)) {
					const elementDataWithAnimation = {
						...elementDataCopy,
						animation: {
							name: itemWithGlobalAnimation.animation.name,
							type: ANIMATION_TYPE_GLOBAL,
						},
					};

					siteDataClone.languages[elementLocale].elements[elementId] = elementDataWithAnimation;
				} else {
					siteDataClone.languages[elementLocale].elements[elementId] = elementDataCopy;
				}

				// Handle website.blocks
				if (mobilePosition) {
					siteDataClone.languages[elementLocale].blocks[blockId].components.splice(mobilePosition, 0, elementId);
				} else {
					siteDataClone.languages[elementLocale].blocks[blockId].components.push(elementId);
				}

				siteDataClone.languages[elementLocale].blocks[blockId].zindexes.push(elementId);

				// Handle website.meta
				if (!siteDataClone.meta[META_ECOMMERCE_TYPE] && isElementEcommerceButton) {
					siteDataClone.meta[META_ECOMMERCE_TYPE] = ECOMMERCE_TYPE_ZYRO;
				}

				dispatch('overwriteWebsiteData', {
					websiteData: siteDataClone,
				});
			} catch (error) {
				dispatch('notifications/notify', {
					message: 'Error while adding element. Refresh page and try again.',
				});
				Sentry.captureException(new Error('Error while adding element'));
				throw error;
			}
		},
		duplicateElement: ({
			getters,
			commit,
			dispatch,
		}, {
			mobileTop,
			desktopTop,
			elementId,
		} = {}) => {
			if (!getters.currentBlock) {
				dispatch('updateCurrentBlockId', getters.currentElementBlockId);
			}

			const originalElement = getters.siteElements[elementId];
			const newElement = patcher.clone(originalElement);
			const newElementId = generateRandomId();

			newElement.mobile.top = mobileTop;
			newElement.desktop.top = desktopTop;
			newElement.animation = originalElement.animation;

			dispatch('addElement', {
				blockId: getters.currentElementBlockId,
				elementData: newElement,
				elementId: newElementId,
			});

			commit(SET_ELEMENT_EDIT_MODE, false);

			// TODO: remove this when EditControls will be refactored
			// dispatch('selectCurrentElement', {
			// elementId: newElementId,
			// });
		},
		removeElement: ({
			state,
			dispatch,
			getters,
		}, {
			elementId,
			locale = state.currentLocale,
		}) => {
			try {
				const siteStore = useSiteStore();
				const elementLocale = getters.isCurrentPagePrivate ? SYSTEM_LOCALE : locale;

				const siteDataWithElementRemoved = removeElement({
					locale: elementLocale,
					siteData: siteStore.site,
					elementId,
				});

				const isTextBox = getters.siteLanguages[elementLocale].elements[elementId].type === ELEMENT_TYPE_TEXT_BOX;

				dispatch('overwriteWebsiteData', {
					websiteData: siteDataWithElementRemoved,
				});

				if (getters.currentPage.type === PAGE_TYPE_BLOG && isTextBox) {
					dispatch('blog/calculateReadTime', {
						pageId: state.currentPageId,
					});
				}

				dispatch('unselectCurrentElement');
			} catch (error) {
				dispatch('notifications/notify', {
					message: 'Error while removing element. Refresh page and try again.',
				});
				Sentry.captureException(new Error('Error while removing element'));
				throw error;
			}
		},
		mergeElementData: ({
			state,
			getters,
			dispatch,
		}, {
			elementId,
			elementData,
			locale = state.currentLocale,
		}) => {
			try {
				const siteStore = useSiteStore();
				const elementLocale = getters.isCurrentPagePrivate ? SYSTEM_LOCALE : locale;
				const mergedElementData = mergeObjects(siteStore.site.languages[elementLocale].elements[elementId], elementData);
				const cleanedElementData = removeNullishEntries(mergedElementData);

				const validatedElementData = {
					...cleanedElementData,
					...getValidatedLayoutPositions({
						elementId,
						element: cleanedElementData,
						blockId: state.currentBlockId,
					}),
				};

				siteStore.setSiteElementData({
					locale: elementLocale,
					elementId,
					data: validatedElementData,
				});

				addBreadcrumb({
					category: 'Merge element data',
					data: {
						locale,
					},
				});
			} catch (error) {
				dispatch('notifications/notify', {
					messageI18nKeyPath: 'builder.notifications.errorWhileUpdatingElement',
				});

				captureException(error);
				throw error;
			}
		},
		mergeBulkElementsData: ({
			state,
			getters,
			dispatch,
		}, { elementsData }) => {
			try {
				const siteStore = useSiteStore();
				const language = state.currentLocale;
				const updatedElementsIds = Object.keys(elementsData);
				const elementsToUpdate = updatedElementsIds.reduce((acc, elementId) => {
					const elementData = elementsData[elementId];
					const mergedElementData = mergeObjects(getters.siteElements[elementId], elementData);
					const cleanedElementData = removeNullishEntries(mergedElementData);
					const validatedElementData = {
						...cleanedElementData,
						...getValidatedLayoutPositions({
							elementId,
							element: cleanedElementData,
							blockId: state.currentBlockId,
						}),
					};

					return {
						...acc,
						[elementId]: validatedElementData,
					};
				}, {});

				siteStore.setSiteElementsData({
					locale: language,
					data: elementsToUpdate,
				});

				addBreadcrumb({
					category: 'Bulk element data merge',
				});
			} catch (error) {
				dispatch('notifications/notify', {
					messageI18nKeyPath: 'builder.notifications.errorWhileUpdatingElement',
				});

				captureException(error);
				throw error;
			}
		},
		mergeCurrentElementData: ({
			state,
			dispatch,
			getters,
		}, { elementData }) => {
			if (!state.currentElementId || !getters.siteElements[state.currentElementId]) return;

			dispatch('mergeElementData', {
				elementId: state.currentElementId,
				elementData,
			});
		},
		moveElementBetweenBlocks: async ({
			getters,
			dispatch,
		}, {
			elementId,
			oldBlockId,
			newBlockId,
		}) => {
			const {
				siteBlocks,
				siteElements,
				'gui/isMobileMode': isMobileMode,
			} = getters;
			const newBlockData = siteBlocks[newBlockId];
			const oldBlockData = siteBlocks[oldBlockId];

			// Remove element from old block
			dispatch('updateBlockData', {
				blockId: oldBlockId,
				blockData: {
					components: oldBlockData.components.filter((id) => id !== elementId),
					zindexes: oldBlockData.zindexes.filter((id) => id !== elementId),
				},
				merge: true,
			});

			// While editing desktop, before moving element to different block
			// first we need to find lowest element and
			// only then add new element right after it
			if (!isMobileMode.value) {
				const blockElements = Object.entries(siteElements)
					.filter(([siteElementId]) => newBlockData.components.includes(siteElementId))
					.map(([_siteElementId, siteElementData]) => siteElementData);

				const lowestElementBottom = getLowestElementBottom({
					blockElements,
					elementPositionKey: 'mobile',
				});

				dispatch('mergeElementData', {
					elementId,
					elementData: {
						mobile: {
							top: lowestElementBottom,
						},
					},

				});
			}

			// Add element to new block
			await dispatch('updateBlockData', {
				blockId: newBlockId,
				blockData: {
					components: [
						...newBlockData.components,
						elementId,
					],
					zindexes: [
						...newBlockData.zindexes,
						elementId,
					],
				},
				merge: true,
			});

			dispatch('updateCurrentBlockId', newBlockId);
		},
		// #endregion
		// #region Block
		updateCurrentBlockId: ({ commit }, blockId) => {
			const siteStore = useSiteStore();

			commit(SET_CURRENT_BLOCK_ID, blockId);
			siteStore.setSiteCurrentBlockId(blockId);
		},
		addBlock: ({
			state,
			getters,
			dispatch,
		}, {
			pageId,
			blockId = generateRandomId(),
			blockData,
			previousBlockId,
			elements = {},
			blocks = {},
			slideshowBlockId,
			slideMetadata = {},
		}) => {
			try {
				const siteStore = useSiteStore();
				const blockLocale = getters.isCurrentPagePrivate ? SYSTEM_LOCALE : state.currentLocale;

				// generate action comes first to create a correct diff of website data
				if (BLOCKS_ECOMMERCE.includes(blockData?.type)) {
					dispatch('generateEcommercePages');
				}

				const siteDataWithAddedBlock = addBlock({
					siteData: siteStore.site,
					pageId,
					blockId,
					blockData,
					previousBlockId,
					elements,
					blocks,
					slideshowBlockId,
					slideMetadata,
					locale: blockLocale,
				});

				dispatch('overwriteWebsiteData', {
					websiteData: siteDataWithAddedBlock,
				});

				dispatch('undoRedo/createSnapshot');
			} catch (error) {
				dispatch('notifications/notify', {
					message: 'Error while adding block. Refresh page and try again.',
				});
				Sentry.captureException(new Error('Error while adding block'));
				throw error;
			}
		},
		removeBlock: ({
			state,
			getters,
			dispatch,
		}, {
			blockId,
			rootBlock = true,
		}) => {
			try {
				const siteStore = useSiteStore();
				const blockLocale = getters.isCurrentPagePrivate ? SYSTEM_LOCALE : state.currentLocale;

				if (rootBlock) {
					dispatch('updateCurrentBlockId', null);
				}

				const siteDataWithBlockRemoved = removeBlock({
					siteData: siteStore.site,
					blockId,
					locale: blockLocale,
				});

				dispatch('overwriteWebsiteData', {
					websiteData: siteDataWithBlockRemoved,
				});

				dispatch('undoRedo/createSnapshot');
			} catch (error) {
				dispatch('notifications/notify', {
					message: 'Error while removing block. Refresh page and try again.',
				});
				Sentry.captureException(new Error('Error while removing block'));
				throw error;
			}
		},
		updateBlockData: ({
			state,
			getters,
			dispatch,
		}, {
			blockId,
			blockData,
			merge = false,
			locale = state.currentLocale,
		}) => {
			try {
				const siteStore = useSiteStore();

				if (!blockId) return;

				const blockLocale = getters.isCurrentPagePrivate ? SYSTEM_LOCALE : locale;
				const mergedBlockData = mergeObjects(siteStore.site.languages[blockLocale].blocks[blockId], blockData);
				const cleanedBlockData = removeNullishEntries(merge ? mergedBlockData : blockData);

				siteStore.setSiteBlockData({
					blockId,
					locale: blockLocale,
					data: cleanedBlockData,
				});

				addBreadcrumb({
					category: 'Update block data',
					data: {
						blockId,
					},
				});
			} catch (error) {
				dispatch('notifications/notify', {
					message: 'Error while updating block. Refresh page and try again.',
				});
				Sentry.captureException(new Error('Error while updating block'));
				throw error;
			}
		},
		// #endregion
		// #region Page
		updateCurrentPageId: ({
			state,
			dispatch,
			getters,
			commit,
		}, pageId = getters.homePageId) => {
			const siteStore = useSiteStore();

			if (getters.isCurrentPageTypeDynamicProduct) {
				useEcommerceStore().setCurrentDynamicPageProductId(null);
			}

			siteStore.setSiteCurrentPageId(pageId);

			dispatch('unselectCurrentElement');
			dispatch('updateCurrentBlockId', null);

			commit(SET_CURRENT_PAGE_ID, pageId);

			window.scrollTo(0, 0);

			// scroll to the top of the page in builder root
			if (state.gui.builderPreviewContainerRef) {
				state.gui.builderPreviewContainerRef.scrollTo(0, 0);
			}

			dispatch('undoRedo/resetUndoRedo');
		},
		addPage: ({
			state,
			dispatch,
			getters,
		}, {
			pageId = generateRandomId(),
			pageData,
			blocks,
			elements,
			navigationItem,
		}) => {
			const siteStore = useSiteStore();
			const locale = state.currentLocale;
			const isPageTypeEcommerce = blocks && Object.values(blocks).some(({ type }) => type === 'BlockEcommerceProductList');

			const siteDataWithPage = addPage({
				locale,
				siteData: siteStore.site,
				pageId,
				pageData: {
					...pageData,
					slug: getBiggestIncrementedString({
						stringToMatch: pageData.slug || PAGE_TYPE_DEFAULT_SLUG,
						strings: Object.values(getters.currentLanguageData.pages).map(({ slug }) => slug),
					}),
				},
				blocks,
				elements,
				navigationItem,
				isPageTypeEcommerce,
			});

			dispatch('overwriteWebsiteData', {
				websiteData: siteDataWithPage,
			});
			dispatch('updateCurrentPageId', pageId);
			// Temporary reset undo/redo after page add/remove, to assure reliable data flow.
			dispatch('undoRedo/resetUndoRedo');
			if (isPageTypeEcommerce) {
				dispatch('generateEcommercePages');
			}

			dispatch('navigation/updateNavigationVisibility');
		},
		updateHomePage: ({ state }, { pageId }) => {
			const siteStore = useSiteStore();
			const locale = state.currentLocale;

			siteStore.setSiteHomePageId({
				locale,
				id: pageId,
			});
		},
		duplicatePage: ({
			dispatch,
			state,
		}, {
			siteData,
			pageId,
			clonedPageId = generateRandomId(),
		}) => {
			const locale = state.currentLocale;

			const {
				clonedPageData,
				clonedBlocks,
				clonedElements,
				clonedPageNavigationItem,
			} = clonePage({
				siteData,
				pageId,
				fromLocale: locale,
				toLocale: locale,
			});

			dispatch('addPage', {
				pageId: clonedPageId,
				pageData: clonedPageData,
				blocks: clonedBlocks,
				elements: clonedElements,
				navigationItem: clonedPageNavigationItem,
				navigateToAddedPage: true,
			});
		},
		setLocaleMeta: ({
			dispatch,
			state,
		}, { metaTitle }) => {
			const siteStore = useSiteStore();
			const locale = state.currentLocale;
			const siteDataClone = patcher.clone(siteStore.site);

			siteDataClone.languages[locale].metaTitle = metaTitle;

			dispatch('overwriteWebsiteData', {
				websiteData: siteDataClone,
			});
		},
		addLanguage: ({
			getters,
			dispatch,
		}, language) => {
			const siteStore = useSiteStore();
			const ecommerceStore = useEcommerceStore();

			const { locale } = language;
			const {
				defaultLocale,
				siteUrl,
				isCurrentSystemLocale,
			} = getters;
			const siteData = patcher.clone(siteStore.site);
			const {
				disclaimer: defaultCookieDisclaimer,
				accept: defaultCookieAcceptText,
				decline: defaultCookieDeclineText,
			} = USER_SITE_TRANSLATIONS.en;

			const languageData = {
				...patcher.clone(siteData.languages[defaultLocale]),
				...language,
			};

			const isDisclaimerUndefined = languageData.cookieBannerDisclaimer === undefined;
			const isDisclaimerDefault = languageData.cookieBannerDisclaimer === defaultCookieDisclaimer;

			// if default locale is system, change it to incoming locale
			if (defaultLocale === SYSTEM_LOCALE) {
				// default locale is changing from system thus we need to remove ecommerce pages

				siteData.languages[SYSTEM_LOCALE] = ecommerceStore.isDynamicPageFlowEnabled
					// Dynamic product page flow
					? removePagesByPageTypeFromLanguage({
						languageData,
						pageType: PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
					})
					// Legacy product page flow
					: removePagesByPageTypeFromLanguage({
						languageData,
						pageType: PAGE_TYPE_ECOMMERCE_PRODUCT,
					});

				siteData.meta.defaultLocale = locale;
				languageData.metaTitle = siteData.meta.metaTitle;
			} else {
				const {
					pages,
					blocks,
					elements,
				} = ecommerceStore.isDynamicPageFlowEnabled
					// Dynamic product page flow
					? removePagesByPageTypeFromLanguage({
						languageData,
						pageType: PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT,
					})
					// Legacy product page flow
					: removePagesByPageTypeFromLanguage({
						languageData,
						pageType: PAGE_TYPE_ECOMMERCE_PRODUCT,
					});

				languageData.pages = pages;
				languageData.blocks = blocks;
				languageData.elements = elements;

				Object.keys(languageData.pages).forEach((pageId) => {
					languageData.pages[pageId].name += ` (${locale.toUpperCase()})`;
				});
			}

			// Remove private page
			const {
				pages: pageWithoutPrivatePage,
				blocks: blocksWithoutPrivatePageBlocks,
				elements: elementsWithoutPrivatePageElements,
			} = removePagesByPageTypeFromLanguage({
				languageData,
				pageType: PAGE_TYPE_PRIVATE,
			});

			if (locale !== defaultLocale && defaultLocale !== SYSTEM_LOCALE) {
				languageData.elements = updateElementHrefs({
					elements: languageData.elements,
					locale,
					siteUrl,
				});
			}

			languageData.cookieBannerDisclaimer = isDisclaimerUndefined || isDisclaimerDefault || !isCurrentSystemLocale
				? (USER_SITE_TRANSLATIONS[locale]?.disclaimer ?? defaultCookieDisclaimer)
				: languageData.cookieBannerDisclaimer;

			languageData.cookieBannerAcceptText = USER_SITE_TRANSLATIONS[locale]?.accept ?? defaultCookieAcceptText;
			languageData.cookieBannerDeclineText = USER_SITE_TRANSLATIONS[locale]?.decline ?? defaultCookieDeclineText;
			languageData.blogReadingTimeText = USER_SITE_TRANSLATIONS[locale]?.readTime ?? USER_SITE_TRANSLATIONS.en.readTime;

			const languageDataWithoutPrivateData = {
				...languageData,
				pages: {
					...pageWithoutPrivatePage,
				},
				blocks: {
					...blocksWithoutPrivatePageBlocks,
				},
				elements: {
					...elementsWithoutPrivatePageElements,
				},
			};

			// Forms must have unique IDs across all languages
			if (locale !== defaultLocale) {
				const formElementIds = Object.entries(languageDataWithoutPrivateData.elements)
					.filter(([_elementId, elementData]) => elementData.type === ELEMENT_TYPE_FORM)
					.map(([elementId]) => elementId);

				const languageDataWithMappedFormIds = mapFormIds(languageDataWithoutPrivateData, formElementIds);

				siteData.languages[locale] = languageDataWithMappedFormIds;
			} else {
				siteData.languages[locale] = languageDataWithoutPrivateData;
			}

			dispatch('overwriteWebsiteData', {
				websiteData: localizeHrefs(siteData, locale),
			});
			dispatch('updateCurrentLocale', locale);
			dispatch('updateCurrentPageId');
			dispatch('navigation/updateNavigationVisibility');
		},
		removeLanguage: async ({
			state,
			getters,
			dispatch,
		}, locale) => {
			const siteStore = useSiteStore();
			let { defaultLocale } = siteStore.site.meta;
			const siteData = patcher.clone(siteStore.site);
			const remainingLanguages = filterObject(siteData.languages, ({ key }) => key !== locale);
			const {
				disclaimer: defaultCookieDisclaimer,
				accept: defaultCookieAcceptText,
				decline: defaultCookieDeclineText,
			} = USER_SITE_TRANSLATIONS.en;

			// if only 'system' locale is left, transfer deleted locale data to system and delete it
			if (Object.keys(remainingLanguages).length === 1) {
				const {
					alt,
					country,
					flagPath,
					locale: _oldLocale,
					name,
					src,
					blogReadingTimeText,
					...restData
				} = patcher.clone(siteData.languages[locale]);

				siteData.languages[SYSTEM_LOCALE] = {
					...restData,
					pages: {
						...restData.pages,
						[PAGE_ID_PRIVATE]: {
							...getters.privatePage,
						},
					},
					blocks: {
						...restData.blocks,
						...getters.privatePageBlocks,
					},
					elements: {
						...restData.elements,
						...getters.privatePageElements,
					},
					cookieBannerDisclaimer: defaultCookieDisclaimer,
					cookieBannerAcceptText: defaultCookieAcceptText,
					cookieBannerDeclineText: defaultCookieDeclineText,
				};
				delete siteData.languages[locale];
			} else {
				siteData.languages = remainingLanguages;
			}

			// if passed locale is the same as current default, override it with first leftover locale (can be undefined)
			if (locale === defaultLocale) {
				defaultLocale = Object.keys(remainingLanguages).find((key) => key !== SYSTEM_LOCALE) ?? SYSTEM_LOCALE;
				siteData.meta.defaultLocale = defaultLocale;
			}

			if (locale === state.currentLocale) {
				// force await to wait for actual locale and page change before overwriting siteData
				await dispatch('updateCurrentLocale', defaultLocale);
				await dispatch('updateCurrentPageId', siteData.languages[defaultLocale].homePageId);
			}

			dispatch('overwriteWebsiteData', {
				websiteData: siteData,
			});
			dispatch('navigation/updateNavigationVisibility');
		},
		updateLanguageVisibility: ({
			dispatch,
			getters,
		}, {
			locale,
			isHidden,
		}) => {
			const languages = {
				...getters.siteLanguages,
				[locale]: {
					...getters.siteLanguages[locale],
					isHidden,
				},
			};

			dispatch('updateLanguages', languages);
		},
		addEmptyPage: ({ dispatch }, {
			pageId = generateRandomId(),
			name = '',
		}) => {
			dispatch('addPage', {
				pageId,
				pageData: {
					name,
					blocks: [],
					type: 'default',
				},
				blocks: {},
				navigationItem: {
					linkType: NAVIGATION_TYPE_PAGE,
					subItems: [],
				},
				navigateToAddedPage: true,
			});
		},
		addStorePage: ({ dispatch }) => {
			const blockId = generateRandomId();
			const ecommerceStore = useEcommerceStore();

			dispatch('addPage', {
				pageData: {
					name: 'Store',
					blocks: [blockId],
					type: 'default',
				},
				blocks: {
					[blockId]: {
						...getEcommerceProductListBlock({
							products: DEMO_PRODUCTS,
							textAlign: 'outlined',
							blockPadding: '100px 16px',
							imageRatio: 'portrait',
							imageHoverEffect: 'swap_image',
							columnCountDesktop: 3,
							columnGapDesktop: 20,
							productsPerPage: 6,
							isButtonEnabled: true,
							buttonDisplay: 'always',
							isButtonFullWidth: true,
							isSortingEnabled: true,
							isCategoryListEnabled: true,
							isTotalProductCountShown: true,
							buttonBorderWidth: 1,
							buttonStyle: {
								'grid-button-primary-color': 'rgb(0, 0, 0)',
								'grid-button-primary-background-color': 'rgba(0, 0, 0, 0)',
								'grid-button-primary-border-color': 'rgb(0, 0, 0)',
							},
						}),
					},
				},
				navigationItem: {
					linkType: NAVIGATION_TYPE_PAGE,
					subItems: [],
				},
			});

			ecommerceStore.fetchProducts();
		},
		addBlogPostPage: async ({
			state,
			dispatch,
		}, {
			postTitle,
			postDescription,
			postContent,
			postThumbnail,
		}) => {
			const siteStore = useSiteStore();
			const locale = state.currentLocale;

			const {
				siteDataWithBlogPostPage,
				pageId: blogPageId,
			} = addBlogPostTemplate({
				locale,
				siteData: siteStore.site,
				postTitle,
				postDescription,
				postContent,
				postThumbnail,
				isDraft: true,
			});

			dispatch('overwriteWebsiteData', {
				websiteData: siteDataWithBlogPostPage,
			});
			dispatch('updateCurrentPageId', blogPageId);
			// Temporary reset undo/redo after page add/remove, to assure reliable data flow.
			dispatch('undoRedo/resetUndoRedo');
		},
		addBlog: async ({
			state,
			getters,
			dispatch,
		}, {
			existingPageId = false,
			existingPagePreviousBlockId,
			pageTitle,
			pageId = generateRandomId(),
			blogPostsData,
			postsPerPage,
			postColumnCount,

		} = {}) => {
			const siteStore = useSiteStore();
			const locale = getters.isCurrentPagePrivate ? SYSTEM_LOCALE : state.currentLocale;

			let siteData = siteStore.site;
			let siteDataWithBlogListAdded = null;

			const blogListBlock = {
				id: generateRandomId(),
				data: getBlogListBlock({
					mockCategories: false,
					postColumnCount,
					postsPerPage,
				}),
			};

			const hasBlogPosts = Object.values(siteData.languages[locale].pages)
				.some((page) => page.type === PAGE_TYPE_BLOG);

			// Blog post data is kept after blog deletion thus we need to check
			// if there are any blog posts and if not, add them
			if (!hasBlogPosts) {
				const siteDataWithBlogPost = blogPostsData
					.reduce((siteDataWithBlogPostPages, blogPostData) => {
						const {
							postTitle,
							postDescription,
							postContent,
							postThumbnail,
						} = blogPostData;

						const { siteDataWithBlogPostPage } = addBlogPostTemplate({
							locale,
							siteData: siteDataWithBlogPostPages,
							isDraft: false,
							postTitle,
							postDescription,
							postContent,
							postThumbnail,
						});

						return siteDataWithBlogPostPage;
					}, siteData);

				siteData = siteDataWithBlogPost;
			}

			// Add blog list page to a current page OR as a new page
			if (existingPageId) {
				siteDataWithBlogListAdded = addBlock({
					locale,
					siteData,
					pageId: state.currentPageId,
					blockId: blogListBlock.id,
					blockData: blogListBlock.data,
					previousBlockId: existingPagePreviousBlockId,
				});
			} else {
				const blogTitleBlockId = generateRandomId();
				const blogTitleBlock = getPageTitleBlock({
					title: pageTitle,
				});

				siteDataWithBlogListAdded = addPage({
					locale,
					siteData,
					pageId,
					pageData: {
						name: pageTitle,
						slug: getBiggestIncrementedString({
							stringToMatch: PAGE_TYPE_BLOG_LIST_SLUG,
							strings: Object.values(getters.currentLanguageData.pages).map(({ slug }) => slug),
						}),
						blocks: [
							blogTitleBlockId,
							blogListBlock.id,
						],
						type: 'default',
					},
					blocks: {
						[blogListBlock.id]: blogListBlock.data,
						[blogTitleBlockId]: blogTitleBlock.blockData,
					},
					elements: {
						...blogTitleBlock.elements,
					},
					navigationItem: {
						linkType: NAVIGATION_TYPE_PAGE,
						subItems: [],
					},
				});
			}

			dispatch('overwriteWebsiteData', {
				websiteData: siteDataWithBlogListAdded,
			});
			dispatch('updateCurrentPageId', existingPageId ? state.currentPageId : pageId);
			// Temporary reset undo/redo after page add/remove, to assure reliable data flow.
			dispatch('undoRedo/resetUndoRedo');

			if (existingPageId) {
				if (state.user?.user) {
					window.hj('identify', state.user.user.id, {
						'builder.blog.insert_blog_list': true,
					});
				}
			}

			await nextTick();

			const {
				pages,
				blocks,
				elements,
			} = siteDataWithBlogListAdded.languages[locale];

			const pageBlocks = Object.fromEntries(
				Object.entries(blocks)
					.filter(([blockId]) => pages[existingPageId || pageId].blocks.includes(blockId)),
			);

			Object.entries(pageBlocks).forEach(([blockId, { components }]) => {
				components.forEach((elementId) => {
					const elementType = elements[elementId].type;

					if (elementType === ELEMENT_TYPE_TEXT_BOX) {
						updateElementPositionFromDOM({
							elementId,
							blockId,
							dispatch,
						});
					}
				});
			});
		},
		addEcommerceProductPages: async ({
			dispatch,
			getters,
			rootGetters,
		}, {
			resetUndo = true,
			pickStylesFromTheme,
		} = {}) => {
			if (!getters['ecommerce/isStoreTypeZyro']) {
				return;
			}

			const siteStore = useSiteStore();
			const ecommerceStore = useEcommerceStore();

			const locale = getters.defaultLocale;
			const storeId = getStoreId(rootGetters.siteMeta);

			// Dynamic product page logic
			if (ecommerceStore.isDynamicPageFlowEnabled) {
				if (!Object.keys(getters.ecommerceDynamicProductPageTemplates).length) {
					const {
						pageData,
						blocks,
					} = getEcommerceProductTemplate();
					const siteDataWithDynamicProductPage = addPage({
						locale,
						siteData: siteStore.site,
						pageId: generateRandomId(),
						pageData,
						blocks,
					});

					dispatch('overwriteWebsiteData', {
						websiteData: siteDataWithDynamicProductPage,
					});
					// Temporary reset undo/redo after page add/remove, to assure reliable data flow.
					dispatch('undoRedo/resetUndoRedo');
					dispatch('navigation/updateNavigationVisibility');
				}

				return;
			}

			// Legacy product page logic
			const isDemoEcommerce = !rootGetters.siteMeta?.ecommerceStoreId;

			const getHiddenStoreProducts = async () => {
				const productData = await getStoreProducts(storeId, {
					isHidden: true,
				});

				return productData.products;
			};

			const hiddenStoreProducts = ecommerceStore.hiddenProducts.length
				? ecommerceStore.hiddenProducts
				: await getHiddenStoreProducts();
			const allProducts = [
				...ecommerceStore.products,
				...hiddenStoreProducts,
			];
			const productPages = getters.ecommerceProductPages;

			let productAndPageIds = Object.keys(productPages).reduce((accumulator, key) => ({
				...accumulator,
				[key]: productPages[key].productId,
			}), {});
			let siteData = patcher.clone(siteStore.site);

			if (!ecommerceStore.hiddenProducts.length) {
				ecommerceStore.setHiddenProducts(hiddenStoreProducts);
			}

			// loop through each product and check whether it has a page created already
			// if not, create site data with a new product page
			allProducts.forEach((productData) => {
				if (Object.values(productAndPageIds).includes(productData.id)) {
					const existingPageId = Object.keys(productAndPageIds).find((key) => productAndPageIds[key] === productData.id);

					// if such page is already created, renew it's meta data
					siteData.languages[locale].pages[existingPageId] = {
						...siteData.languages[locale].pages[existingPageId],
						name: productData.title,
						meta: {
							...siteData.languages[locale].pages[existingPageId].meta,
							...(isDemoEcommerce ? {
								ogImageOrigin: 'other',
								ogImagePath: productData.thumbnail,
								ogImageAlt: productData.thumbnail ? productData.title : null,
							} : {}),
						},
					};

					productAndPageIds = omit(productAndPageIds, existingPageId);

					return;
				}

				let productBlockStylesFromTheme;

				// for setting default products styles from theme template
				if (pickStylesFromTheme) {
					const productBlockFromTheme = siteData.languages[locale].blocks[Object.values(productPages)[0].blocks[0]];

					productBlockStylesFromTheme = {
						background: productBlockFromTheme.background,
						buttonStyle: productBlockFromTheme.buttonStyle,
						buttonType: productBlockFromTheme.buttonType,
						galleryPlacement: productBlockFromTheme.galleryPlacement,
						imageBorderRadius: productBlockFromTheme.imageBorderRadius,
						imageRatio: productBlockFromTheme.imageRatio,
						navigationArrowsColor: productBlockFromTheme.navigationArrowsColor,
						navigationThumbnailArrowsColor: productBlockFromTheme.navigationThumbnailArrowsColor,
						settings: productBlockFromTheme.settings,
						textColorVars: productBlockFromTheme.textColorVars,
					};
				}

				const { siteDataWithProductPage } = addEcommerceProductPageTemplate({
					siteData,
					productData,
					locale,
					productBlockStylesFromTheme,
					isDemoEcommerce,
				});

				siteData = siteDataWithProductPage;
			});

			// remove pages of the products that do not exist anymore
			Object.keys(productAndPageIds).forEach((pageId) => {
				siteData = removePage({
					siteData,
					pageId,
					locale,
				});
			});

			dispatch('overwriteWebsiteData', {
				websiteData: siteData,
			});

			if (resetUndo) {
				dispatch('undoRedo/resetUndoRedo');
			}
		},
		generateEcommercePages: ({
			getters,
			dispatch,
		}) => {
			const ecommerceStore = useEcommerceStore();
			const { products } = ecommerceStore;

			if (!getters.ecommerceShoppingCart) {
				dispatch('ecommerce/getSettings');
				dispatch('ecommerce/getCategories');
			}

			if (products.length) {
				dispatch('addEcommerceProductPages', {
					resetUndo: false,
					pickStylesFromTheme: false,
				});
			} else {
				Promise.allSettled([
					dispatch('ecommerce/getVariantsQuantity'),
					ecommerceStore.fetchProducts({
						resetUndoForPageCreation: false,
					}),
				]);
			}
		},
		addEcommerceShoppingCart: ({ dispatch }, ecommerceShoppingCart) => {
			const siteStore = useSiteStore();
			const websiteData = {
				...siteStore.site,
				ecommerceShoppingCart,
			};

			dispatch('overwriteWebsiteData', {
				websiteData,
			});
		},
		updateEcommerceProductPagesBlocks: ({
			state,
			getters,
		}, blockData) => {
			const siteStore = useSiteStore();
			const locale = state.currentLocale;
			const productPageBlockIds = Object.values(getters.ecommerceProductPages).flatMap((page) => page.blocks);
			const productBlocks = Object.keys(getters.siteBlocks)
				.filter((key) => getters.siteBlocks[key].type === BLOCK_TYPE_ECOMMERCE_PRODUCT && productPageBlockIds.includes(key));

			productBlocks.forEach((blockId) => {
				const blockDataClone = patcher.clone(blockData);

				blockDataClone.product = {
					...siteStore.site.languages[locale].blocks[blockId].product,
				};

				siteStore.setSiteBlockData({
					blockId,
					locale,
					data: blockDataClone,
				});
			});
		},
		updateCurrentLocale: ({
			commit,
			dispatch,
		}, locale) => {
			commit(SET_CURRENT_LOCALE, locale);
			dispatch('updateCurrentPageId');
			dispatch('removeNonExistingBlocksFromPages');
		},
		updateLanguages: (_, languages) => {
			const siteStore = useSiteStore();

			siteStore.setSiteLanguagesData(languages);
		},
		updateDefaultLocale: ({
			dispatch,
			getters,
		}, locale) => {
			const siteStore = useSiteStore();
			const earlierDefaultLocale = getters.defaultLocale;

			siteStore.setSiteMetaData({
				key: 'defaultLocale',
				value: locale,
			});

			const {
				[locale]: defaultLocale,
				...restLanguages
			} = getters.siteLanguages;

			// When you change default language in DrawerList, it is moved to top of the list
			const reorderedLanguages = {
				[locale]:
				{
					...defaultLocale,
					isHidden: false, // Force the default language to be visible
				},
				...restLanguages,
			};

			dispatch('updateLanguages', reorderedLanguages);

			if (getters['ecommerce/isStoreTypeZyro']) {
				// move all ecommerce pages and components in it to new default language
				let siteDataClone = patcher.clone(siteStore.site);
				const earlierDefaultLocaleData = getters.siteLanguages[earlierDefaultLocale];
				const ecommercePages = Object.fromEntries(
					Object.entries(earlierDefaultLocaleData.pages).filter(([, value]) => value.type === PAGE_TYPE_ECOMMERCE_PRODUCT),
				);
				const ecommerceDynamicPages = Object.fromEntries(
					Object.entries(earlierDefaultLocaleData.pages).filter((
						[, value],
					) => value.type === PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT),
				);

				const ecommercePageBlockIds = [
					...Object.keys(ecommercePages).flatMap((pageId) => ecommercePages[pageId].blocks),
					...Object.keys(ecommerceDynamicPages).flatMap((pageId) => ecommerceDynamicPages[pageId].blocks),
				];
				const ecommercePageBlocks = Object.fromEntries(Object.entries(earlierDefaultLocaleData.blocks).filter(
					([key]) => ecommercePageBlockIds.includes(key),
				));
				const ecommercePageElementIds = ecommercePageBlockIds.flatMap(
					(blockId) => earlierDefaultLocaleData.blocks[blockId].components,
				).filter((value) => value);
				const ecommercePageElements = Object.fromEntries(Object.entries(earlierDefaultLocaleData.elements).filter(
					([key]) => ecommercePageElementIds.includes(key),
				));

				// remove ecommerce pages from earlier default locale
				Object.keys(ecommercePages).forEach((pageId) => {
					siteDataClone = removePage({
						siteData: siteDataClone,
						pageId,
						locale: earlierDefaultLocale,
					});
				});

				// remove ecommerce dynamic pages from earlier default locale
				Object.keys(ecommerceDynamicPages).forEach((pageId) => {
					siteDataClone = removePage({
						siteData: siteDataClone,
						pageId,
						locale: earlierDefaultLocale,
					});
				});

				// add ecommerce pages and their content to new default locale
				siteDataClone.languages[locale] = {
					...siteDataClone.languages[locale],
					pages: {
						...siteDataClone.languages[locale].pages,
						...ecommercePages,
						...ecommerceDynamicPages,
					},
					blocks: {
						...siteDataClone.languages[locale].blocks,
						...ecommercePageBlocks,
					},
					elements: {
						...siteDataClone.languages[locale].elements,
						...ecommercePageElements,
					},
				};

				dispatch('overwriteWebsiteData', {
					websiteData: {
						...siteDataClone,
					},
				});
			}
		},
		removePage: ({
			state,
			dispatch,
		}, { pageId }) => {
			const siteStore = useSiteStore();
			const locale = state.currentLocale;

			dispatch('unselectCurrentElement');
			dispatch('updateCurrentBlockId', null);

			const siteDataWithRemovedPage = removePage({
				siteData: siteStore.site,
				pageId,
				locale,
			});

			dispatch('overwriteWebsiteData', {
				websiteData: siteDataWithRemovedPage,
			});
			dispatch('updateCurrentPageId');
			// Temporary reset undo/redo after page add/remove, to assure reliable data flow.
			dispatch('undoRedo/resetUndoRedo');
			dispatch('navigation/updateNavigationVisibility');
		},
		mergeLanguageData: ({
			state,
			getters,
			dispatch,
		}, {
			locale = state.currentLocale,
			languageData,
		}) => {
			try {
				const siteStore = useSiteStore();
				const newLanguageData = mergeObjects(getters.siteLanguages[locale], languageData);

				siteStore.setSiteLanguageData({
					locale,
					data: newLanguageData,
				});

				addBreadcrumb({
					category: 'Merge language data',
					data: {
						locale,
					},
				});
			} catch (error) {
				dispatch('notifications/notify', {
					message: 'Error while updating page. Refresh editor and try again.',
				});
				Sentry.captureException(new Error('Error while updating page'));
				throw error;
			}
		},
		mergePageData: ({
			state,
			getters,
			dispatch,
		}, {
			locale = state.currentLocale,
			pageId,
			pageData,
		}) => {
			const pageLocale = getters.isCurrentPagePrivate ? SYSTEM_LOCALE : locale;

			try {
				const siteStore = useSiteStore();
				const mergedPageData = mergeObjects(siteStore.site.languages[pageLocale].pages[pageId], pageData);
				const cleanedPageData = removeNullishEntries(mergedPageData);

				siteStore.setSitePageData({
					pageId,
					locale: pageLocale,
					data: cleanedPageData,
				});
			} catch (error) {
				dispatch('notifications/notify', {
					message: 'Error while updating page. Refresh editor and try again.',
				});
				Sentry.captureException(new Error('Error while updating page'));
				throw error;
			}
		},
		setStyleProperty: (_, {
			element,
			property,
			value,
		}) => {
			const siteStore = useSiteStore();

			siteStore.setSiteStyleProperty({
				element,
				property,
				value,
			});
		},
		setWebsiteSiteMeta: (_, {
			key,
			value,
		}) => {
			const siteStore = useSiteStore();

			siteStore.setSiteMetaData({
				key,
				value,
			});
		},

		// #endregion
	},
};

export const store = createStore(storeConfig);
